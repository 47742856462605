#styleguide-container {
  min-width: 970px;
}

ul.nav {
  position: fixed;
  width: 240px;
  height: 100%;
  padding: 10px 30px 15px;
  overflow-y: scroll;

  li {
    margin-top: 5px;

    a {
      font-size: 15px;
      color: $white;

      &.active, &:hover {
        color: $black;
      }
    }
  }
}

ul.sub-nav {
  li {
    margin-left: 15px;
  }
}

ul.sub-sub-nav {
  li {
    margin-left: 30px;

    a {
      font-size: 12px;
    }
  }
}

#main-wrapper {
  position: relative;
  margin-left: 240px;
  padding: 30px;
}

section {
  padding-top: 30px;

  article {
    >h2 {
      margin: 15px 0;
    }
  }

  pre[class*="language-"] {
    margin: 15px 0;
    font-size: 15px;
  }

  ul.list {
    margin-left: 30px;
    list-style-type: disc;
  }

  .burbank-small-bold {
    font-family: $burbank-small-bold;
  }

  .apercu-light {
    font-family: $apercu-light;
  }

  .apercu-regular {
    font-family: $apercu-regular;
  }

  .apercu-medium {
    font-family: $apercu-medium;
  }

  .apercu-bold {
    font-family: $apercu-bold;
  }

  .coverly-regular {
    font-family: $coverly-regular;
  }

  .colors {
    @include clearfix;
  }

  .swatch {
    float: left;
    width: 143px;
    margin: 15px;
    text-align: center;
  }

  .color {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 1px solid black;
    @include rounded(50px);

    &.black {
      background-color: $black;
    }

    &.light-black {
      background-color: $light-black;
    }

    &.white {
      background-color: $white;
    }

    &.darkest-grey {
      background-color: $darkest-grey;
    }

    &.darker-grey {
      background-color: $darker-grey;
    }

    &.dark-grey {
      background-color: $dark-grey;
    }

    &.light-grey {
      background-color: $light-grey;
    }

    &.lighter-grey {
      background-color: $lighter-grey;
    }

    &.lightest-grey {
      background-color: $lightest-grey;
    }

    &.pale-grey {
      background-color: $pale-grey;
    }

    &.darkest-blue {
      background-color: $darkest-blue;
    }

    &.darker-blue {
      background-color: $darker-blue;
    }

    &.dark-blue {
      background-color: $dark-blue;
    }

    &.blue {
      background-color: $blue;
    }

    &.dark-green {
      background-color: $dark-green;
    }

    &.green {
      background-color: $green;
    }

    &.dark-red {
      background-color: $dark-red;
    }

    &.red {
      background-color: $red;
    }

    &.dark-pink {
      background-color: $dark-pink;
    }

    &.pink {
      background-color: $pink;
    }

    &.blue-50 {
      background-color: $blue-50;
    }

    &.blue-100 {
      background-color: $blue-100;
    }

    &.blue-200 {
      background-color: $blue-200;
    }

    &.blue-400 {
      background-color: $blue-400;
    }

    &.blue-800 {
      background-color: $blue-800;
    }

    &.blue-900 {
      background-color: $blue-900;
    }

    &.gray-50 {
      background-color: $gray-50;
    }

    &.gray-100 {
      background-color: $gray-100;
    }

    &.gray-200 {
      background-color: $gray-200;
    }

    &.gray-700 {
      background-color: $gray-700;
    }

    &.gray-500 {
      background-color: $gray-500;
    }

    &.gray-900 {
      background-color: $gray-900;
    }

    &.green-50 {
      background-color: $green-50;
    }

    &.green-200 {
      background-color: $green-200;
    }

    &.green-300 {
      background-color: $green-300;
    }

    &.yellow-100 {
      background-color: $yellow-100;
    }

    &.yellow-500 {
      background-color: $yellow-500;
    }

    &.yellow-700 {
      background-color: $yellow-700;
    }

    &.red-50 {
      background-color: $red-50;
    }

    &.red-700 {
      background-color: $red-700;
    }

    &.red-900 {
      background-color: $red-900;
    }
  }

  .icons {
    i {
      margin-right: 15px;
    }
  }

  .example {
    margin: 15px 0;
    padding: 15px;
    border: 2px dashed $lighter-grey;
    @include clearfix;
  }

  .truncate-example {
    width: 250px;
  }

  .col-example {
    padding: 15px;
    border: 2px solid $lighter-grey;
    background-color: $lightest-grey;
  }

  .hero-example {
    height: 450px;
    background: url("../../images/school-portrait.png") no-repeat center center;
    background-size: cover;
    @include crooked(skewY(1deg), $white);
  }

  .tooltip-example .tooltip {
    width: 220px;
    left: -18px;

    &:before, &:after {
      left: 15px;
    }
  }
}

#images {
  .img-container {
    @include clearfix;
  }
}
