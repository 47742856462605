@mixin rounded($radius) {
  @include border-left-radius($radius);
  @include border-right-radius($radius);
}

@mixin rounded-top($radius) {
  @include border-top-radius(5px);
}

@mixin rounded-bottom($radius) {
  @include border-bottom-radius(5px);
}

@mixin ellipsis($max-width) {
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin drop-shadow($x: 0, $y: 0, $blur: 2px, $spread: 0, $color: rgba(0, 0, 0, 0.25)) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin crooked($movement, $color) {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    width: 110%;
    height: 100px;
    bottom: -80px;
    left: -5%;
    background: $color;
    overflow: visible;
    @include transform($movement);
  }
}

@mixin remove-crooked {
  &:after {
    display: none;
  }
}

@mixin divider($width, $color) {
  position: relative;
  text-align: center;

  &:before, &:after {
    content: "";
    position: absolute;
    width: calc(50% - #{$width});
    height: 1px;
    top: 50%;
    background-color: $color;
  }

  &:before {
    right: 0%;
  }

  &:after {
    left: 0%;
  }
}
