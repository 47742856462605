@charset "UTF-8";

@font-face {
  font-family: "barkbox";
  src: url("../../fonts/icons/barkbox.eot");
  src: url("../../fonts/icons/barkbox.eot?#iefix") format("embedded-opentype"),
       url("../../fonts/icons/barkbox.woff") format("woff"),
       url("../../fonts/icons/barkbox.ttf") format("truetype"),
       url("../../fonts/icons/barkbox.svg#barkbox") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  display: block;
  font-family: "barkbox" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-check:before {
  content: "\e000";
}
.icon-expand:before {
  content: "\e001";
}
.icon-compress:before {
  content: "\e002";
}
.icon-lock:before {
  content: "\e003";
}
.icon-navicon:before {
  content: "\e004";
}
.icon-info-circle:before {
  content: "\e005";
}
.icon-chat-bubble:before {
  content: "\e006";
}
.icon-angle-down:before {
  content: "\e007";
}
.icon-angle-left:before {
  content: "\e008";
}
.icon-angle-right:before {
  content: "\e009";
}
.icon-angle-up:before {
  content: "\e00a";
}
.icon-pencil:before {
  content: "\e00b";
}
.icon-home:before {
  content: "\e00c";
}
.icon-user:before {
  content: "\e00d";
}
.icon-credit-card:before {
  content: "\e00e";
}
.icon-birthday-cake:before {
  content: "\e00f";
}
.icon-envelope-o:before {
  content: "\e010";
}
.icon-barkicons-69:before {
  content: "\e011";
}
.icon-edit:before {
  content: "\e012";
}
.icon-flip:before {
  content: "\e013";
}
.icon-checkmark:before {
  content: "\e014";
}
.icon-barkicons-66:before {
  content: "\e015";
}
.icon-close:before {
  content: "\e016";
}
