@font-face {
  font-family: "burbank-small-bold";
  src: url("../fonts/burbank-small-bold.eot");
  src: url("../fonts/burbank-small-bold.svg");
  src: url("../fonts/burbank-small-bold.woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "burbank-small-medium";
  src: url("../fonts/burbank-small-medium.eot");
  src: url("../fonts/burbank-small-medium.svg");
  src: url("../fonts/burbank-small-medium.woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "apercu-light";
  src: url("../fonts/apercu-light.eot");
  src: url("../fonts/apercu-light.eot?#iefix") format("embedded-opentype"),
       url("../fonts/apercu-light.woff") format("woff"),
       url("../fonts/apercu-light.ttf") format("truetype"),
       url("../fonts/apercu-light.svg#apercuregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercu-regular";
  src: url("../fonts/apercu-regular.eot");
  src: url("../fonts/apercu-regular.eot?#iefix") format("embedded-opentype"),
       url("../fonts/apercu-regular.woff") format("woff"),
       url("../fonts/apercu-regular.ttf") format("truetype"),
       url("../fonts/apercu-regular.svg#apercuregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercu-medium";
  src: url("../fonts/apercu-medium.eot");
  src: url("../fonts/apercu-medium.eot?#iefix") format("embedded-opentype"),
       url("../fonts/apercu-medium.woff") format("woff"),
       url("../fonts/apercu-medium.ttf") format("truetype"),
       url("../fonts/apercu-medium.svg#apercumedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "apercu-bold";
  src: url("../fonts/apercu-bold.eot");
  src: url("../fonts/apercu-bold.eot?#iefix") format("embedded-opentype"),
       url("../fonts/apercu-bold.woff") format("woff"),
       url("../fonts/apercu-bold.ttf") format("truetype"),
       url("../fonts/apercu-bold.svg#apercubold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "coverly-regular";
  src: url("../fonts/coverly-regular.eot");
  src: url("../fonts/coverly-regular.eot?#iefix") format("embedded-opentype"),
       url("../fonts/coverly-regular.woff") format("woff"),
       url("../fonts/coverly-regular.ttf") format("truetype"),
       url("../fonts/coverly-regular.svg#apercubold") format("svg");
  font-weight: normal;
  font-style: normal;
}
