$black: #221E1F;
$light-black: #222222;

$white: #FFFFFF;

$darkest-grey: #333333;
$darker-grey: #666666;
$dark-grey: #999999;
$light-grey: #D8D8D8;
$lighter-grey: #DFDFDF;
$lightest-grey: #F0F0F0;
$pale-grey: #F7F7F7;

$darkest-blue: #283F5D;
$darker-blue: #0E6285;
$dark-blue: #219CD3;
$blue: #45B1E2;

$dark-green: #7DAA44;
$green: #A4CF5F;

$yellow: #F7D157;

$dark-red: #83250B;
$red: #B2320F;

$dark-pink: #DE5568;
$pink: #E6808E;

$error-background: #F0D6CF;
$error-text: #873C30;
$warning-background: #FCF9E6;
$warning-text: #93895C;
$success-background: #E5F0D6;
$success-text: #61822E;

$facebook: #4565A1;
$facebook-hover: #364F7D;
$twitter: #00ACEB;
$twitter-hover: #0087B8;

$super-chewer-primary: #1D4F91;
$super-chewer-secondary: #31619D;
$super-chewer-tertiary: #8EA7C8;

$admin-dark-purple: #4E4EB5;
$admin-purple: #7272C4;

$burbank-small-bold: "burbank-small-bold", sans-serif;
$burbank-small-medium: "burbank-small-medium", sans-serif;

$apercu-light: "apercu-light", sans-serif;
$apercu-regular: "apercu-regular", sans-serif;
$apercu-medium: "apercu-medium", sans-serif;
$apercu-bold: "apercu-bold", sans-serif;

$coverly-regular: "coverly-regular", sans-serif;

$veneer: "veneer", sans-serif;

$icons: "barkbox", sans-serif;

$divider-zindex: 5;
$tooltip-zindex: 10;
$chat-zindex: 20;
$fixed-header-zindex: 30;
$sub-nav-zindex: 40;
$overlay-zindex: 50;
$godly-zindex: 2147483645;

// New V4 Color Variables:
$blue-50: #E1F6FE;
$blue-100: #B2E7FC;
$blue-200: #7FD7FB;
$blue-400: #0FBCF9;
$blue-800: #007CC2;
$blue-900:#265D84;
$gray-50: #EEE;
$gray-100: #D1D5D9;
$gray-200: #B2BAC2;
$gray-500: #657687;
$gray-700: #485460;
$gray-900:#272D34;
$green-50: #E0FAEB;
$green-200: #7CEAAB;
$green-300: #00E187;
$yellow-100: #FFF8C2;
$yellow-500: #FDE42A;
$yellow-700: #FDBC23;
$red-50: #FFEBEF;
$red-700: #E71131;
$red-900: #CC001B;
