// html elements

*, *:after, *:before {
  @include box-sizing(border-box);
}

html.barkbox-app {
  background-color: $white;

  body {
    background-color: $white;
  }

  // hot fix
  #main-container {
    @include transition(margin-left 0.3s ease-in-out);
  }

  .wrapper {
    width: 964px;
  }

  h1 {
    &.tighten {
      line-height: 40px;
    }
  }

  .top-medium {
    margin-top: 20px;
  }

  button {
    font-size: 13px;
    font-family: $apercu-bold;
    border-radius: 2px;
  }

  .bold {
    font-family: $apercu-bold;
  }

  .top-extra {
    margin-top: 60px;
  }
}

html, body {
  width: 100%;
  background-color: $blue;
  color: $black;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  overflow-y: scroll;
}

h1 {
  font-family: $burbank-small-bold;
  font-size: 36px;
  line-height: 1.4em;
}

h2 {
  font-family: $burbank-small-bold;
  font-size: 22px;
  line-height: 1.4em;
}

h3 {
  font-family: $apercu-regular;
  font-size: 18px;
  line-height: 1.4em;
}

h4 {
  font-family: $apercu-regular;
  font-size: 15px;
  line-height: 1.4em;
}

h5 {
  font-family: $apercu-regular;
  font-size: 13px;
  line-height: 1.4em;
}

h6 {
  font-family: $apercu-regular;
  font-size: 10px;
  line-height: 1.4em;
}

p {
  font-family: $apercu-regular;
  font-size: 17px;
  line-height: 1.4em;

  &.notice {
    color: $dark-grey;
    font-size: 14px;

    a {
      color: $dark-grey;
      font-size: 14px;
      text-decoration: underline;
    }

    &.notice-small {
      max-width: 305px;
    }
  }
}

a {
  font-family: $apercu-regular;
  font-size: 17px;
  line-height: 1.4em;
  text-decoration: none;
  color: $blue;
  cursor: pointer;
  vertical-align: middle;

  &:hover {
    color: $dark-blue;
  }
}

img {
  vertical-align: middle;

  &.full {
    width: 100%;
  }
}

form {
  float: left;
  width: 100%;
  @include clearfix;

  &.grey-fields {
    input, textarea, select {
      background-color: $lightest-grey;
    }
  }
}

fieldset {
  position: relative;
  float: left;
  @include clearfix;

  &.radio {
    padding: 10px;
    border: 1px solid $light-grey;
    background-color: $white;
    cursor: pointer;

    &.radio-top {
      @include rounded-top(5px);
    }

    &.radio-bottom {
      @include rounded-bottom(5px);
    }

    &.single-border {
      border-bottom: none;
    }

    p {
      line-height: 28px;
    }
  }

  &.full {
    width: 100%;
  }

  &.half {
    width: 50%;
  }

  &.two-thirds {
    width: 66.667%;
  }

  &.one-third {
    width: 33.333%;
  }

  &.three-fourths {
    width: 75%;
  }

  &.one-fourth {
    width: 25%;
  }
}

label:not([for*="checkbox-"]):not([for*="radio-"]) {
  display: block;
  margin-bottom: 5px;
  font-family: $apercu-regular;
  font-size: 13px;
  line-height: 1.4em;
  text-transform: uppercase;
  color: $dark-grey;

  a {
    font-size: 13px;
  }
}

label[for*="checkbox-"], label[for*="radio-"] {
  cursor: pointer;
}

input[type="text"], input[type="email"], input[type="password"], input[type="tel"] {
  width: 100%;
  height: 50px;
  padding: 10px;
  border: 1px solid $light-grey;
  font-family: $apercu-regular;
  font-size: 17px;
  line-height: 1.4em;
  outline: none;
  -webkit-appearance: none;
  @include rounded(5px);

  &:focus {
    border: 1px solid $blue;
  }

  &.with-icon {
    padding-left: 30px;
  }
}

/* START: Custom Checkbox */
input[type="checkbox"] {
  position: absolute;
  left: -999em;
}

input[type="checkbox"] + p {
  position: relative;
  overflow: hidden;
}

input[type="checkbox"] + p::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  background-color: $white;
  border: 1px solid $light-grey;
  border-radius: 2px;
}

input[type="checkbox"]:checked + p::before {
  background-color: $green;
  border: 1px solid $green;
}

input[type="checkbox"]:checked + p::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 4px;
  top: 8px;
  left: 2px;
  border: 2px solid $white;
  border-top: none;
  border-right: none;
  @include transform(rotate(-45deg));
}
/* END: Custom Checkbox */

/* START: Custom Radio Button */
input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

input[type="radio"] + p:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border-radius: 12px;
  border: 3px solid $white;
  @include drop-shadow(0, 0, 0, 2px, $light-grey);
}

input[type="radio"]:checked + p:before {
  background-color: $white;
  border: 3px solid $green;
  @include drop-shadow(0, 0, 0, 2px, $green);
}
/* END: Custom Radio Button */

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid $light-grey;
  font-family: $apercu-regular;
  font-size: 17px;
  line-height: 1.4em;
  resize: none;
  outline: none;
  -webkit-appearance: none;
  @include rounded(5px);

  &:focus {
    border: 1px solid $blue;
  }
}

select {
  width: 100%;
  height: 50px;
  background-color: $white;
  border: 1px solid $light-grey;
  font-family: $apercu-regular;
  font-size: 17px;
  line-height: 50px;
  @include rounded(5px);
}

button, input[type="submit"] {
  height: 50px;
  padding: 0 30px;
  border: none;
  font-family: $apercu-medium;
  font-size: 15px;
  line-height: 50px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: $white;
  cursor: pointer;
  @include appearance(none);
  @include rounded(0);

  &.full {
    width: 100%;
  }

  &.dark-grey {
    background-color: $dark-grey;

    &:hover {
      background-color: $darker-grey;
    }
  }

  &.green {
    background-color: $green;

    &:hover {
      background-color: $dark-green;
    }
  }

  &.blue {
    background-color: $blue;

    &:hover {
      background-color: $dark-blue;
    }
  }

  &.white {
    color: $blue;
    background-color: $white;

    &:hover {
      color: $dark-blue;
      opacity: .8;
    }
  }

  &.blue-outline {
    border: 2px solid $blue;
    color: $blue;
    line-height: 46px;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: $blue;
    }
  }

  &.red {
    background-color: $red;

    &:hover {
      background-color: $dark-red;
    }
  }

  &.facebook {
    background-color: $facebook;

    &:hover {
      background-color: $facebook-hover;
    }
  }

  &.twitter {
    background-color: $twitter;

    &:hover {
      background-color: $twitter-hover;
    }
  }

  &.disabled {
    background-color: $dark-grey;
    background-size: 30px 30px;
    cursor: default;
    opacity: 1;
    @include background-image(linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent));
    @include animation(animate-stripes 3s linear infinite);

    &:hover {
      background-color: $dark-grey;
    }
  }

  img {
    width: 25%;
    max-width: 32px;
    margin-right: 15px;
  }
}

// base structures

#main-wrapper {
  background-color: $white;
}

#main-container {
  width: 100%;
  margin-top: 50px;
}

#preload-images {
  display: none;
}

.wrapper {
  position: relative;
  width: 940px;
  height: 100%;
  margin: 0 auto;
  @include clearfix;
}

.large-wrapper {
  position: relative;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  @include clearfix;
}

.col {
  position: relative;
  float: left;
  @include clearfix;

  &.swap {
    float: right;
  }

  &.full {
    width: 100%;
  }

  &.half {
    width: 50%;
  }

  &.two-thirds {
    width: 66.667%;
  }

  &.one-third {
    width: 33.333%;
  }

  &.three-fourths {
    width: 75%;
  }

  &.one-fourth {
    width: 25%;
  }

  &.one-fifth {
    width: 20%;
  }

  &.two-fifths {
    width: 40%;
  }

  &.three-fifths {
    width: 60%;
  }
}

.group {
  position: relative;
  width: 100%;
  @include clearfix;

  &.rounded {
    @include rounded(5px);
  }

  &.white {
    background-color: $white;
  }

  &.dashed {
    border: 1px dashed $lighter-grey;
  }

  .group-header {
    padding: 15px 25px;
    @include clearfix;

    &.border {
      border-bottom: 1px solid $light-grey;
      color: $darkest-grey;
    }

    &.blue {
      background-color: $blue;
      text-align: center;
      color: $white;
    }
  }

  .group-content {
    padding: 25px;
    color: $darkest-grey;
    @include clearfix;

    &+.group-content {
      padding-top: 0;
    }
  }
}

.tooltip-wrapper {
  float: left;
  width: 100%;

  *, label:not([for*="checkbox-"]):not([for*="radio-"]) {
    display: inline-block;
  }

  .icon-info {
    position: relative;
    cursor: pointer;

    .no-touch &:hover, &.touch {
      .tooltip {
        display: block;
      }
    }

    .tooltip {
      display: none;
      position: absolute;
      top: 25px;
      padding: 15px;
      background-color: $white;
      text-align: left;
      z-index: $tooltip-zindex;
      @include rounded(5px);
      @include drop-shadow;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $darkest-grey;
      }

      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $white;
      }
    }
  }
}

.solid-divider {
  border-top: 1px solid $lighter-grey;
}

.dashed-divider {
  border-top: 1px dashed $lighter-grey;
}

.top-divider {
  margin-top: 30px;
}

// base modifiers

.right-text {
  text-align: right;
}

.center-text {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.emphasize {
  font-family: $burbank-small-bold;
}

.italicize {
  font-style: italic;
}

.coverly {
  font-family: $coverly-regular;

  a {
    font-family: $coverly-regular;
  }
}

.truncate {
  @include ellipsis(100%);
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.pull-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.top {
  margin-top: 30px;
}

.top-mini {
  margin-top: 15px;
}

.left {
  padding-right: 15px;
}

.left-mini {
  padding-right: 7.5px;
}

.middle {
  padding-left: 15px;
  padding-right: 15px;
}

.middle-mini {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.right {
  padding-left: 15px;
}

.right-mini {
  padding-left: 7.5px;
}

.grey-background {
  background-color: $lightest-grey;
}

.no-overflow {
  overflow: hidden;
}

.inline {
  display: inline;
}

// overlay and modal
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $overlay-zindex;

  .modal {
    position: absolute;
    width: 40%;
    top: 25%;
    left: 50%;
    margin-left: -20%;
    @include drop-shadow;
  }
}

// for eventual billing component

// .payment-logo {
//   height: 22px;
// }

// .credit-card-logo {
//   margin-left: 18px;
//   vertical-align: top;
// }

// .icon-lock {
//   position: absolute;
//   top: 39px;
//   left: 9px;
// }

// animations

@-webkit-keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 60px 0;
  }
}

@-moz-keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 60px 0;
  }
}

// responsiveness

@media (max-width: 969px) {
  html.barkbox-app {
    .wrapper {
      width: 100%;
      padding: 0 15px
    }
  }

  .wrapper, .large-wrapper {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 20px;
  }

  p, a, input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea, select {
    font-size: 15px;
  }

  input[type="checkbox"]:checked + p::after {
    top: 6px;
  }

  .zopim {
    display: none !important;
  }
}
