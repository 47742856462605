.alert-bar {
  &.success {
    background-color: $success-background;
    color: $success-text;
  }

  &.warning {
    background-color: $warning-background;
    color: $warning-text;
  }

  &.error {
    background-color: $error-background;
    color: $error-text;
  }

  .alert {
    min-height: 50px;
    margin-right: 50px;
    padding: 15px 0;
  }

  p, a {
    font-size: 15px;
  }

  .icon-times {
    position: absolute;
    top: 15px;
    right: 0;
    cursor: pointer;
  }
}

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], textarea, select {
  &.error {
    border-color: $red;
  }
}

.inline-error {
  p, a {
    font-size: 13px;
  }

  p {
    margin-top: 5px;
    color: $red;
  }
}

@media (max-width: 969px) {
  .alert-bar {
    .icon-times {
      right: 15px;
    }
  }
}
